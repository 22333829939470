
import { mdiDownload } from "@mdi/js"

export default {
    name: "FileDownload",
    props: {
        /** File href */
        file: {
            type: String,
            required: false,
        },
        /** Makes overlay persistent */
        persistent: {
            type: Boolean,
            default: false,
        },
        /** Name of the file */
        name: {
            type: String,
            default: "",
        },
        /** Background image src */
        background: {
            type: String,
            default: null,
        },
        /** custom icon  */
        icon: {
            type: String,
            default: mdiDownload,
        },
    },
    data: () => ({
        mdiDownload,
    }),
    computed: {
        backgroundStyle() {
            return { "background-image": `url(${this.background})` }
        },
        iconSize() {
            if (this.$breakpoint.smAndDown || this.persistent) return 25
            return 75
        },
    },
}
