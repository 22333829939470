
import FileDownload from "@/components/base/FileDownload"
import Button from "@/components/base/Button"
import { mdiDownload } from "@mdi/js"

export default {
    name: "FileDownloadBlok",
    components: { FileDownload, Button },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    icons: {
        mdiDownload,
    },
    computed: {
        isFlat() {
            return this.blok.variant === "flat"
        },
        autoHeight() {
            return this.blok.autoHeight
        },
        width() {
            return this.blok.width
        },
        flatStyle() {
            return {
                width: this.width,
                height: this.autoHeight ? "auto" : "630px",
            }
        },
    },
}
